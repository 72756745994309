import React from 'react';
import { HeroComponent } from '../components/Hero';
import { WrapperPageComponent } from '../components/WrapperPage';
import { HowToUseItComponent } from '../components/HowToUseIt';
import { WashHandsComponent } from '../components/WashHands';
import { WhyMasksComponent } from '../components/WhyMasks';
import { WhereMasksComponent } from '../components/WhereMasks';
import { DigitalMediumsComponent } from '../components/DigitalMediums';
import { PrintMediumsComponent } from '../components/PrintMediums';
import { WeUsingMasksComponent } from '../components/WeUsingMasks';

const HomePage = () => {
  return (
    <section className="homepage">
      <HeroComponent />
      <WrapperPageComponent variant="pink">
        <HowToUseItComponent />
      </WrapperPageComponent>
      <WrapperPageComponent>
        <WashHandsComponent />
      </WrapperPageComponent>
      <WrapperPageComponent variant="pink">
        <WhyMasksComponent />
      </WrapperPageComponent>
      <WrapperPageComponent>
        <WhereMasksComponent />
      </WrapperPageComponent>
      <WrapperPageComponent variant="pink">
        <WeUsingMasksComponent />
      </WrapperPageComponent>
      <WrapperPageComponent>
        <DigitalMediumsComponent />
      </WrapperPageComponent>
      <WrapperPageComponent variant="pink">
        <PrintMediumsComponent />
      </WrapperPageComponent>
    </section>
  );
};

export default HomePage;
