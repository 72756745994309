import React from 'react';
import './styles.scss';

export const BlockPageComponent = ({ pretitle, title, text, ...props }) => (
  <div className="block-page-component">
    <div className="container">
      <div className="block-page-component-head">
        <div className="block-page-component-head__pretitle nodos-pretitle">
          {pretitle}
        </div>
        <div className="block-page-component-head__title nodos-title">
          {title}
        </div>
        <div
          className="block-page-component-head__text"
          dangerouslySetInnerHTML={{ __html: text }}
        ></div>
      </div>
      <div className="block-page-component-content">{props.children}</div>
    </div>
  </div>
);
