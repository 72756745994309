import React from 'react';
import Logo from '../../assets/logo.png';
import { ButtonComponent } from '../Button';
import './styles.scss';

export const HeaderComponent = () => {
  return (
    <header className="header">
      <div className="container">
        <nav className="navbar">
          <div className="navbar__logo">
            <img src={Logo} alt="#YoUsoMascarilla" />
          </div>
          <div className="navbar__item">INICIO</div>
          <div className="navbar__item">¿CÓMO USARLA?</div>
          <div className="navbar__item">¿POR QUÉ USARLA?</div>
          <div className="navbar__item">¿DÓNDE USARLA?</div>
          <div className="navbar__item">SÚMATE</div>
          <div className="navbar__spacer"></div>
          <div className="navbar__cta">
            <ButtonComponent variant="secondary" text="KIT DE COMUNICACIÓN" />
          </div>
        </nav>
      </div>
    </header>
  );
};
