import React from 'react';
import './styles.scss';

export const ButtonComponent = ({ href, text, ...props }) => {
  const variante = props.variant || 'primary';
  return !!href ? (
    <a className={'btn ' + ('btn--' + variante)}>{text}</a>
  ) : (
    <button className={'btn ' + ('btn--' + variante)}>{text}</button>
  );
};
