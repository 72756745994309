import React from 'react';
import './styles.scss';
import { ButtonComponent } from '../Button';
import Hands from '../../assets/hands.png';
import { BlockPageComponent } from '../BlockPage';

export const WashHandsComponent = () => {
  return (
    <BlockPageComponent
      pretitle="Y siempre recuerda"
      title="Lava tus manos y mantén la distancia."
      text="La mascarilla de ninguna manera reemplaza el lavado de manos con
      jabón, ni el distanciamiento social de al menos 1 metro. Todas estas
      acciones son complementarias."
    >
      <div className="wash-hands-component">
        <div className="wash-hands-component__image">
          <img src={Hands} alt="Lava tus manos y mantén la distancia" />
        </div>
        <div className="wash-hands-component__cta">
          <ButtonComponent text="POR QUÉ USARLA" />
        </div>
      </div>
    </BlockPageComponent>
  );
};
