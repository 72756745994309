import React from 'react';
import './styles.scss';
import { CardComponent } from '../Card';
import MaskAction from '../../assets/mask-action.png';
import Desktpop from '../../assets/desk.png';
import Hospital from '../../assets/hospital.png';
import Bus from '../../assets/bus.png';
import Bag from '../../assets/bag.png';
import Building from '../../assets/building.png';
import Products from '../../assets/products.png';
import { BlockPageComponent } from '../BlockPage';

const cards = [
  {
    img: MaskAction,
    text:
      'Al hablar, toser o estornudar. Esos son los momentos donde tener puesta la mascarilla es más importante.',
  },
  {
    img: Desktpop,
    text: 'En tu trabajo, así sea dentro de una oficina.',
  },
  {
    img: Hospital,
    text: 'En el mercado, supermercado o farmacia.',
  },
  {
    img: Bus,
    text: 'En el transporte público o en los taxis.',
  },
  {
    img: Bag,
    text: 'Al sacar la basura o pasear a tu mascota.',
  },
  {
    img: Products,
    text: 'Si manipulas productos que serán vendidos o repartidos.',
  },
  {
    img: Building,
    text: 'En áreas comunes: públicas o privadas.',
  },
];

export const WhereMasksComponent = () => {
  return (
    <BlockPageComponent
      pretitle="¿Dónde usar mascarilla?"
      title="Es importante usar mascarilla porque..."
    >
      <div className="where">
        <div className="where-cards">
          {cards.map((card) => (
            <div className="where-cards__item">
              <CardComponent
                variant="pink"
                img={card.img}
                title={card.title}
                text={card.text}
              />
            </div>
          ))}
        </div>
      </div>
    </BlockPageComponent>
  );
};
