import React from 'react';
import './styles.scss';
import { CardComponent } from '../Card';
import Virus from '../../assets/virus.png';
import Chain from '../../assets/chain.png';
import Asymptomatic from '../../assets/asymptomatic.png';
import Mask from '../../assets/mask-example.png';
import { BlockPageAlternativeComponent } from '../BlockPageAlternative';

const cards = [
  {
    img: Virus,
    title: 'Bloquea las partículas invisibles',
    text: `<p>que generamos al hablar, toser, estornudar y respirar</p>`,
  },
  {
    img: Chain,
    title: 'Detienes la cadena de contagios',
    text:
      '<p>si el 80% de la población usara mascarilla, la cadena de contagios se detendría</p>',
  },
  {
    img: Asymptomatic,
    title: 'Muchos de los infectados no presenta síntomas',
    text: '<p>Es decir, pueden contagiar a otros sin saberlo.</p>',
  },
  {
    img: Mask,
    title: 'Enseñas con el ejemplo',
    text:
      '<p>Conviértete en un ejemplo de responsabilidad en tu comunidad e inspira a otros a usarla también.</p>',
  },
];

export const WhyMasksComponent = () => {
  return (
    <BlockPageAlternativeComponent
      pretitle="¿Por qué usar mascarilla?"
      title="Es importante usar mascarilla porque..."
    >
      <div className="why-cards">
        {cards.map((card) => (
          <div className="why-cards__item">
            <CardComponent img={card.img} title={card.title} text={card.text} />
          </div>
        ))}
      </div>
    </BlockPageAlternativeComponent>
  );
};
