import React from 'react';
import './styles.scss';
import { ButtonComponent } from '../Button';

export const ElementComponent = ({ img, title, link, link_text, ...props }) => {
  const variant = props.variant || '';
  return (
    <div className={'element-component ' + variant}>
      <div className="element-component__image">
        <img src={img} alt={title} />
      </div>
      <div className="element-component__title">{title}</div>
      <ButtonComponent variant="secondary" href={link} text={link_text} />
    </div>
  );
};
