import React from 'react';
import './styles.scss';

export const BlockPageAlternativeComponent = ({
  pretitle,
  title,
  text,
  ...props
}) => (
  <div className="container">
    <div className="block-page-alternative-component">
      <div className="block-page-alternative-component-content">
        <div className="block-page-alternative-component-content__pretitle nodos-pretitle">
          {pretitle}
        </div>
        <div className="block-page-alternative-component-content__title nodos-title">
          {title}
        </div>
      </div>
      <div className="block-page-alternative-component-children">
        {props.children}
      </div>
    </div>
  </div>
);
