import React from 'react';
import './styles.scss';
import { CardComponent } from '../Card';
import Hands from '../../assets/hands.png';
import Mask from '../../assets/mask.png';
import Wash from '../../assets/wash.png';
import { BlockPageAlternativeComponent } from '../BlockPageAlternative';

const cards = [
  {
    img: Hands,
    title: 'Al ponértela:',
    text: `<p>Primero, lávate las manos.</p><p>La mascarilla deberá cubrir toda tu nariz y tu barbilla.</p><p>No deberá ser holgada.</p>`,
  },
  {
    img: Mask,
    title: 'Al usarla:',
    text:
      '<p>No toques la tela de la mascarilla.</p><p>No te la retires en ningún momento</p><p>No te toques el rostro</p>',
  },
  {
    img: Wash,
    title: 'Al quitártela',
    text:
      '<p>No te la quites hasta llegar a casa</p><p>Retírala desde los elásticos y lávate las manos</p><p>Si es de tela lávala con detergente después de cada uso.</p>',
  },
];

export const HowToUseItComponent = () => {
  return (
    <BlockPageAlternativeComponent
      pretitle="¿Cómo usar mascarilla?"
      title="Su eficacia dependerá de si la usas correctamente."
    >
      <div className="how-cards">
        {cards.map((card) => (
          <div className="how-cards__item">
            <CardComponent img={card.img} title={card.title} text={card.text} />
          </div>
        ))}
      </div>
    </BlockPageAlternativeComponent>
  );
};
