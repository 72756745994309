import React from 'react';
import './styles.scss';

export const CardComponent = ({ img, title, text, ...props }) => {
  const variant = props.variant || '';
  return (
    <div className={'card-component ' + variant}>
      <div className="card-component__image">
        <img src={img} alt={title} />
      </div>
      {!!title && <div className="card-component__title">{title}</div>}
      <div
        className="card-component__text"
        dangerouslySetInnerHTML={{ __html: text }}
      ></div>
    </div>
  );
};
