import React from 'react';
import { ElementComponent } from '../Element';
import { BlockPageComponent } from '../BlockPage';
import Laptop from '../../assets/laptop.png';
import Mask from '../../assets/mask.png';
import NoMask from '../../assets/mask-no-action.png';
import Hospital from '../../assets/hospital.png';

const elements = [
  {
    img: Laptop,
    title: 'Elementos de campaña',
    link: '',
    text_link: 'DESCARGAR',
  },
  {
    img: Mask,
    title: 'Infografía: ¿Por qué?',
    link: '',
    text_link: 'DESCARGAR',
  },
  {
    img: NoMask,
    title: 'Infografía: ¿Cómo?',
    link: '',
    text_link: 'DESCARGAR',
  },
  {
    img: Hospital,
    title: 'Infografía: ¿Dónde?',
    link: '',
    text_link: 'DESCARGAR',
  },
];

export const DigitalMediumsComponent = () => {
  return (
    <BlockPageComponent
      pretitle="Ayúdanos a salvar más vidas"
      title="A través de medios digitales"
      text="Descarga nuestro kit de videos e infografías para compartir en tus redes sociales con tus amigos y familiares en tu empresa o institución educativa. <br> ¡Todo esfuerzo suma!"
    >
      <div className="medium-component">
        {elements.map((elm, id) => (
          <div key={id} className="medium-component__item">
            <ElementComponent
              img={elm.img}
              title={elm.title}
              link={elm.link}
              link_text={elm.text_link}
            />
          </div>
        ))}
      </div>
    </BlockPageComponent>
  );
};
