import React from 'react';
import We from '../../assets/we.png';
import './styles.scss';
import { ButtonComponent } from '../Button';

export const WeUsingMasksComponent = () => {
  return (
    <div className="container">
      <div className="we-using-masks-component">
        <div className="we-using-masks-component-content">
          <div className="we-using-masks-component-content__text">
            Ellos ya se sumaron a{' '}
            <span className="color-red">#YoUsoMascarilla</span>
          </div>
          <div className="we-using-masks-component-content__cta">
            <ButtonComponent text="ÚNETE" />
          </div>
        </div>
        <div className="we-using-masks-component-image">
          <img src={We} alt="#yousomascarilla" />
        </div>
      </div>
    </div>
  );
};
