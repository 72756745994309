import React from 'react';
import { ButtonComponent } from '../Button';
import Banner from '../../assets/banner.png';
import './styles.scss';

export const HeroComponent = () => {
  return (
    <div className="hero">
      <div className="hero__container container">
        <div className="hero-content">
          <h2>
            <div className="hero-content__line">
              <span>No todos los</span>
            </div>
            <div className="hero-content__line">
              <span>héroes usan</span>
            </div>
            <div className="hero-content__line">
              <span>capa, pero todos</span>
            </div>
            <div className="hero-content__line">
              <span className="color-red">usan mascarilla</span>
            </div>
          </h2>
          <ButtonComponent text="CÓMO USARLA CORRECTAMENTE" />
        </div>
        <div className="hero-image">
          <img src={Banner} alt="Todos los héroes usan máscarilla" />
        </div>
      </div>
    </div>
  );
};
