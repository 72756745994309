import React from 'react';
import './styles.scss';

export const FooterComponent = () => (
  <div className="container">
    <footer className="footer">
      <div className="footer__item footer__logo"></div>
      <div className="footer__item footer__cc">
        Este contenido está bajo licencia Creative Commons (CC BY 4.0)
      </div>
      <div className="footer__item footer__sponsored">Un proyecto de</div>
    </footer>
  </div>
);
